import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { debounce, useForkRef } from '@material-ui/core/utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import ButtonBase from '@material-ui/core/ButtonBase'
import Close from 'components/icons/Close'
import { productType } from 'utils'
import ProductDetails from 'containers/ProductDetails'
import ProductFlowChoices from 'containers/ProductFlowChoices'
import ProductFlowControl from 'containers/ProductFlowControl'
import ProductFlowCta from 'containers/ProductFlowCta'
import ProductFlowCtaDecorator from 'containers/ProductFlowCtaDecorator'
import ProductFlowPopup from 'containers/ProductFlowPopup'
import ProductFlowPopupContainer from 'containers/ProductFlowPopupContainer'
import RouterLink from 'containers/RouterLink'
import LinkArrow from 'components/LinkArrow'
import Button from 'components/Button'
import Typography from 'components/Typography'

const HEADER_LABELS_RESERVED_HEIGHT = 440
const IMAGE_MAXIMUM_SCALE = 1.8

export const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      '--header-gutter': `${theme.spacing(2)}px`,
      '--header-size': '72px',
      '--main-gutter': `${theme.spacing(2)}px`,
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        '--header-gutter': `${theme.spacing(4)}px`,
        '--header-size': 'var(--slide-collapsed-size-desktop)',
        display: 'flex',
      },
    },
    header: {
      flexShrink: 0,
      position: 'relative',
      height: 'var(--header-size)',
      width: '100%',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.complex,
      }),
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        width: 'var(--header-size)',
        height: 'auto',
        '$active &': {
          transform: 'translate3d(calc(var(--main-gutter) / 2), 0, 0)',
        },
      },
    },
    headerLabelContainer: {
      position: 'absolute',
      width: 'var(--header-size)',
      height: 'var(--header-size)',
      '&:nth-child(1)': {
        left: 0,
        top: 0,
      },
      '&:nth-child(2)': {
        right: 0,
        bottom: 0,
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        transform: 'rotate(90deg)',
      },
    },
    headerLabel: {
      ...theme.typography.button,
      position: 'absolute',
      top: '50%',
      left: 'inherit',
      right: 'inherit',
      margin: '0 var(--header-gutter)',
      transform: 'translateY(-50%)',
      letterSpacing: '0.065em',
      lineHeight: 1,
      whiteSpace: 'nowrap',
      '$headerLabelContainer:last-child &': {
        color: theme.palette.text.hint,
      },
    },
    main: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(0, 'var(--main-gutter)', 6),
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        padding: theme.spacing(0, 'var(--main-gutter)', 8, 'var(--main-gutter)'),
        paddingRight: 'calc(var(--header-size) - 10px)',
      },
    },
    mainProductShowcaseInactive: {
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        padding: theme.spacing(8, 'var(--main-gutter)'),
      },
    },
    layout: {
      display: 'grid',
      gridGap: theme.spacing(4),
      flexGrow: 1,
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        gridGap: theme.spacing(2, '10vw'),
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '& > * ': {
        height: 'fit-content',
      },
    },
    mediaArea: {
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        gridColumn: 'span 2',
      },
    },
    mediaAreaIngredientsCollapsed: {
      marginLeft: 8,
    },
    contentArea: {
      transition: theme.transitions.create(['transform', 'opacity'], {
        duration: theme.transitions.duration.standard,
      }),
      '$inactive &': {
        transform: 'translate3d(0, 40px, 0)',
        transitionDelay: '0ms',
        opacity: 0,
      },
    },
    detailsArea: {
      transitionDelay: '175ms',
    },
    purchaseArea: {
      display: 'flex',
      justifyContent: 'flex-end',
      transitionDelay: '250ms',
    },
    mediaContainer: {
      '--aspect-ratio': 153 / 353,
      transformOrigin: 'left center',
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        '--aspect-ratio': 480 / 353,
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        '--aspect-ratio': 1129 / 353,
      },
      [theme.breakpoints.down(props.BREAKPOINT_KEY_DOWN)]: {
        ...theme.mixins.contain(160),
        transform: 'none !important', // Use `!important` instead of a `useMediaQuery` solution.
      },
      '&.initialized': {
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.complex,
        }),
      },
    },
    mediaContainerIngredients: {
      maxWidth: '100%',
      minWidth: '100%',
      '--aspect-ratio': 345 / 201,
      transformOrigin: 'left center',
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        maxWidth: '90%',
        minWidth: '90%',
        '--aspect-ratio': 480 / 282,
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        maxWidth: '90%',
        minWidth: '90%',
        '--aspect-ratio': 1129 / 324,
      },
      [theme.breakpoints.down(props.BREAKPOINT_KEY_DOWN)]: {
        ...theme.mixins.contain(160),
        transform: 'none !important', // Use `!important` instead of a `useMediaQuery` solution.
      },
      '&.initialized': {
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.complex,
        }),
      },
    },
    media: {},
    contentContainer: {
      width: '100%',
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        maxWidth: 'clamp(435px, 34vw, 643px)',
      },
    },
    link: {
      marginTop: theme.spacing(2),
    },
    ctaContainer: {
      position: 'relative',
    },
    cta: {
      marginTop: '24px',
    },
    active: {},
    inactive: {},
    description: {
      padding: theme.spacing(5, 0),
    },
    ctaDecorator: {
      paddingTop: theme.spacing(5),
    },
    closeIcon: {
      display: 'none',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: 'fit-content',
      width: '100%',
      paddingTop: theme.spacing(4),
      '& > *': {
        cursor: 'pointer',
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_UP)]: {
        display: 'flex',
      },
      [theme.breakpoints.up(props.BREAKPOINT_KEY_MD)]: {
        gridColumn: 'span 2',
      },
    },
    firstSlideCloseIcon: {
      visibility: 'hidden',
    },
    headerInactive: {
      visibility: 'hidden',
    },
    collapsedCloseIcon: {
      display: 'none',
    },
  }))

const ProductShowcaseSlide = React.forwardRef(function ProductShowcaseSlide(props, ref) {
  const {
    active,
    className,
    mediaProps,
    product,
    handleSlideClick,
    index,
    breakpoints,
    type = 'product',
    ...other
  } = props

  const rootRef = React.useRef(null)
  const handleRef = useForkRef(rootRef, ref)
  const mainRef = React.useRef(null)
  const layoutRef = React.useRef(null)
  const mediaContainerRef = React.useRef(null)
  const mediaRef = React.useRef(null)

  const classes = useStyles(breakpoints)()

  const [mainHeight, setMainHeight] = React.useState(0)
  const [layoutHeight, setLayoutHeight] = React.useState(0)
  const [mediaHeight, setMediaHeight] = React.useState(0)
  const [selectedPrice, setSelectedPrice] = React.useState(
    product?.prices?.[0] || { lineType: 'R' },
  )

  const syncHeights = React.useCallback(() => {
    setMainHeight(mainRef.current?.clientHeight)
    setLayoutHeight(layoutRef.current?.clientHeight)
    setMediaHeight(mediaRef.current?.clientHeight)
  }, [])

  const handleLoaded = React.useCallback(() => {
    syncHeights()
  }, [syncHeights])

  const handleFaded = React.useCallback(() => {
    mediaContainerRef.current.classList.add('initialized')
  }, [])

  React.useEffect(() => {
    const handleResize = debounce(() => {
      syncHeights()
    })

    window.addEventListener('resize', handleResize, { passive: true })
    return () => {
      handleResize.clear()
      window.removeEventListener('resize', handleResize)
    }
  }, [syncHeights])

  React.useEffect(() => {
    const x = !active ? 'calc((var(--header-size) + var(--main-gutter)) * -1)' : 0
    let y = 0
    let scale = 1

    if (!active && mediaHeight) {
      y = `${(layoutHeight - mediaHeight) / 2}px`

      const missingHeight = mainHeight - mediaHeight
      const targetHeight = mediaHeight + missingHeight - HEADER_LABELS_RESERVED_HEIGHT
      scale = Math.min(targetHeight / mediaHeight, IMAGE_MAXIMUM_SCALE)
    }

    mediaContainerRef.current.style.transform = `translate3d(${x}, ${y}, 0) scale(${scale})`
  }, [active, mainHeight, layoutHeight, mediaHeight])

  let cType = 'outlined'

  if (product.ctaType?.includes('outlined')) {
    cType = 'outlined'
  } else if (product.ctaType?.includes('contained')) {
    cType = 'contained'
  } else {
    cType = product.ctaType
  }

  return (
    <div
      className={clsx(
        classes.root,
        {
          [classes.active]: active,
          [classes.inactive]: !active,
        },
        className,
      )}
      ref={handleRef}
      {...other}
    >
      <ButtonBase
        className={classes.header}
        onClick={(e) => handleSlideClick(e, index - 1)}
        aria-label={`slide-mobile-${index + 1}`}
        value={index}
      >
        <div className={classes.headerLabelContainer}>
          <span className={classes.headerLabel}>{product.name}</span>
        </div>

        <div className={clsx(classes.headerLabelContainer, classes.closeIconContainer)}>
          <span
            className={clsx(classes.headerLabel, {
              [classes.headerInactive]: active && type === 'ingredient',
            })}
          >
            {product.nameSuffix}
          </span>
        </div>
      </ButtonBase>
      <div
        className={clsx(classes.main, {
          [classes.mainProductShowcaseInactive]: type === 'product' && !active,
        })}
        ref={mainRef}
      >
        <div className={classes.layout} ref={layoutRef}>
          <div
            className={clsx(classes.closeIcon, {
              [classes.firstSlideCloseIcon]: index === 0,
              [classes.collapsedCloseIcon]: !active,
            })}
          >
            <Close onClick={(e) => handleSlideClick(e, index - 1)} value={index} />
          </div>

          <div
            className={clsx(classes.mediaArea, {
              [classes.mediaAreaIngredientsCollapsed]: !active && type === 'ingredient',
            })}
          >
            <MediaReveal
              className={clsx({
                [classes.mediaContainerIngredients]: type === 'ingredient',
                [classes.mediaContainer]: type !== 'ingredient',
              })}
              onLoaded={handleLoaded}
              ref={mediaContainerRef}
              ratio
            >
              <Media
                className={classes.media}
                {...mediaProps}
                onTransitionEnd={handleFaded}
                ref={mediaRef}
              />
            </MediaReveal>
          </div>

          <div className={clsx(classes.contentArea, classes.detailsArea)}>
            <div className={classes.contentContainer}>
              <ProductDetails product={product} hideDescription type={type} />

              {product?.description && (
                <Typography className={classes.description} paragraph>
                  {product?.description}
                </Typography>
              )}

              {!product.hideButton && type !== 'ingredient' && (
                <>
                  {product.ctaType === 'link' ? (
                    <LinkArrow className={classes.link} component={RouterLink} href={product.href}>
                      {product.ctaLabel}
                    </LinkArrow>
                  ) : (
                    <Button
                      className={classes.cta}
                      component={RouterLink}
                      href={product.href}
                      variant={cType}
                      size={product.ctaButtonSize}
                      fullWidth={product.ctaButtonFullRow}
                    >
                      {product.ctaLabel}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>

          {!product.hideButton && type !== 'ingredient' && (
            <div className={clsx(classes.contentArea, classes.purchaseArea)}>
              <div className={classes.contentContainer}>
                <ProductFlowControl product={product} setSelectedPrice={setSelectedPrice}>
                  <ProductFlowCtaDecorator
                    selectedPrice={selectedPrice}
                    showPriceName
                    className={classes.ctaDecorator}
                    selectedPriceName={selectedPrice}
                  >
                    <ProductFlowPopupContainer>
                      <ProductFlowPopup>
                        <ProductFlowChoices />
                      </ProductFlowPopup>

                      <ProductFlowCta />
                    </ProductFlowPopupContainer>
                  </ProductFlowCtaDecorator>
                </ProductFlowControl>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

ProductShowcaseSlide.propTypes = {
  active: PropTypes.bool,
  breakpoints: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  mediaProps: PropTypes.object,
  product: productType.isRequired,
  handleSlideClick: PropTypes.func,
  index: PropTypes.number,
}

export default ProductShowcaseSlide
