import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Rating from '@material-ui/lab/Rating'
import { useRouter } from 'next/router'
import { useReviews, useGlobal } from 'api'
import { useProductFlowControl } from 'containers/ProductFlowControl'
import Link from 'components/Link'
import Html from 'components/Html'
import { useTranslations } from 'containers/Translations/TranslationsContext'

const useStyles = makeStyles((theme) => ({
  root: theme.mixins.verticalRhythm(0.5),
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  rowPush: {
    marginLeft: 'auto',
  },
  supplyLabel: {
    ...theme.typography.overline,
    color: theme.palette.text.secondary,
    height: 18,
  },
  priceLabel: {
    ...theme.typography.subtitle1,
    marginRight: 'auto',
  },
  defaultPriceLabel: {
    ...theme.typography.subtitle1,
    marginRight: 'auto',
    fontSize: 14,
    paddingLeft: theme.spacing(0.5),
    textDecoration: 'line-through',
  },
  rating: {},
  ratingLabel: {
    ...theme.typography.overline,
    marginLeft: theme.spacing(1),
  },
  ctaContainer: {
    position: 'relative',
    marginTop: theme.spacing(1.5),
  },
  ratingsButton: {
    display: 'flex',
  },
}))

const ProductFlowCtaDecorator = React.forwardRef(function ProductFlowCtaDecorator(props, ref) {
  const {
    children,
    className,
    showFromPrice = true,
    showReview = true,
    showPriceName = false,
    selectedPrice,
    priceLabel,
    reviewProductCodes,
    ...other
  } = props
  const classes = useStyles(props)
  const t = useTranslations()

  const { product } = useProductFlowControl()

  const { onFormatCurrency } = useGlobal()

  const { totalScore, totalReviews } = useReviews(reviewProductCodes || product?.productCode)

  const router = useRouter()

  const handleRatingsClick = React.useCallback(() => {
    const reviewsOnPage = document.querySelector('#reviews')

    if (reviewsOnPage) {
      reviewsOnPage.scrollIntoView({ behavior: 'smooth' })
    } else {
      router.push(`${product.href}?reviews`)
    }
  }, [product, router])

  const priceFrom = selectedPrice?.price || product.priceFrom
  const defaultPriceFrom = selectedPrice?.defaultPrice || product.defaultPriceFrom

  return (
    <div className={clsx(classes.root, className)} ref={ref} {...other}>
      <div className={classes.row}>
        <span className={classes.supplyLabel}>
          {showPriceName && selectedPrice ? (
            selectedPrice?.name
          ) : (
            <Html
              style={{ textAlign: 'justify' }}
              dangerouslySetInnerHTML={{ __html: product.custom6 }}
            />
          )}
        </span>
      </div>

      <div className={classes.row}>
        {showFromPrice ? (
          <span className={classes.priceLabel}>
            {priceLabel && priceLabel !== '' ? priceLabel : t('Web.Price.From')}{' '}
            {priceFrom < defaultPriceFrom && (
              <>
                <span className={classes.defaultPriceLabel}>
                  {onFormatCurrency(defaultPriceFrom)}
                </span>{' '}
              </>
            )}
            {onFormatCurrency(priceFrom)}
          </span>
        ) : (
          <span className={classes.priceLabel} />
        )}

        {showReview && (
          <Link // eslint-disable-line jsx-a11y/anchor-is-valid
            display="block"
            component="button"
            type="button"
            className={classes.ratingsButton}
            onClick={handleRatingsClick}
          >
            <Rating className={classes.rating} size="small" value={totalScore} readOnly />
            <span className={classes.ratingLabel}>
              {totalReviews} {t('Web.Price.Reviews')}
            </span>
          </Link>
        )}
      </div>

      <div className={classes.ctaContainer}>{children}</div>
    </div>
  )
})

ProductFlowCtaDecorator.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  showFromPrice: PropTypes.bool,
  showReview: PropTypes.bool,
  priceLabel: PropTypes.string,
  showPriceName: PropTypes.bool,
  selectedPrice: PropTypes.object,
  reviewProductCodes: PropTypes.string,
}

export default ProductFlowCtaDecorator
